<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">

                        <div class="">
                            <span class="font-semibold text-base">Kalibrasi Perangkat</span>
                        </div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">
                           <div class="bg-yellow-300 text-lg px-4 py-2">
                               <span class="font-bold">!PERINGATAN</span> Setelah melakukan kalibrasi mohon tunggu 5 menit untuk data terbaru 
                           </div>
                           <div class="mt-4">
                               <table class="table-auto w-full text-xs overflow-x-auto">
                                    <tr class="text-left">
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">Nama</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">{{addData.name}}</th>
                                       
                                    </tr>
                                    <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Ketinggian Air cm</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">{{parseFloat(addData.real_ketinggian * 100).toFixed(2)}}cm</th>
                                       
                                    </tr>
                                    <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Ketinggian Air m</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">{{addData.real_ketinggian}}m</th>
                                       
                                    </tr>
                                    <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Ketinggian Sensor</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">{{addData.level}}m</th>
                                       
                                    </tr>
                                    <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Terakhir Diperbaharui</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">{{parseDate(addData.last_update,'YYYY-MM-DD HH:mm:ss')}}</th>
                                       
                                    </tr>
                                     <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Titik Nol</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100 flex items-center">
                                            <input type="text" name="" class="rounded border-gray-200 border" id="" v-model="addData.titik_nol" >
                                            <span class="text-red-500 ml-2" v-if="status_error">Tidak Boleh Plus</span>
                                        </th>
                                       
                                    </tr>
                                     <tr class="text-left">
                                         <th class=" text-gray-500 py-2 px-4 border border-gray-100">Titik Nol Elevasi</th>
                                        <th class=" text-gray-500 py-2 px-4 border border-gray-100">
                                            <input type="text" name="" id="" class="rounded border-gray-200 border" v-model="addData.titik_elevasi">
                                        </th>
                                       
                                    </tr>
                                
                                
                                    
                                </table>
                           </div>
                            <div class="float-right">
                            <button
                                :class="`text-center py-2 px-8 rounded
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                focus:outline-none my-1`"
                                @click="$router.push('/device')"

                            >Batal</button>

                            <button
                                @click="postData()"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>
                       </div>
                        </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({

        disabled:false,

        keyword:'',
        foto:'',
        status_error:false,
        dataGsm:[],
        addData:{
            name:'',
            code:'',
            latitude:'',
            longitude:'',
            gsm_id:'',
            foto:'',
            nohp:'',
            titik_nol:'',
            siaga_1:'',
            siaga_2:'',
            status:'',
            siaga_3:'',
            siaga_tiga:'',
            siaga_4:'',
        },
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
        getGsm() {
            this.axios.get('v1/masterdata/18',this.config)
            .then((ress) => {
                this.dataGsm = ress.data.Masterdata_details
     
            })
        },
       

        changeProv() {
            this.district = []
            this.addData.city_id = ''
            this.addData.dis_id = ''
            this.getCity()
        },
        changeCity() {
            this.addData.dis_id = ''
            this.getDis()
        },
        async postData(){
            this.status_error = false
            if (this.addData.titik_nol >= 1) {
                this.status_error = true

            } else {
                let data = new FormData()
                data.append('titik_nol',this.addData.titik_nol)
                data.append('titik_elevasi',this.addData.titik_elevasi)
                this.axios.put('v1/device/' + this.$route.params.id,data,this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,
                    })
                    this.$router.push('/device')
                })
                .catch(err => {
                    if (err.response.status == 400) {
                            this.$refs.form.setErrors(err.response.data.message)
                    }
                })
           
            }
           
        },

        getData() {
            this.axios.get('v1/device/' + this.$route.params.id,this.config)
            .then((ress) => {
                this.addData = ress.data
                if (this.addData.foto) {
                    this.addData.foto = this.default_url + this.addData.foto
                }
            })
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.addData.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
    
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    async created() {
        await this.getData()
        
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>